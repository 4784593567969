export const isPhone = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/
export const isEmail = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
export const isWorkPhone = /(^(\d{11})$|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/
export const passReg = /^(?![0-9]+$)(?![a-zA-Z]+$)(?![\W]+$)[0-9A-Za-z\W]{8,20}$/
export const isIdentitycard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/


export function organCodeValidator (rule, value, callback) {
  const reg = /^[A-Z0-9]{18}$/;
  if (reg.test(value)) {
    callback();
  } else {
    callback(new Error('统一社会信用代码18位数字或大写字母组成'));
  }
}

export function idNumberValidator (rule, value, callback) {
  // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  if (reg.test(value)) {
    callback();
  } else {
    callback(new Error('身份证号格式不正确'));
  }
}

export function passwordValidator (rule, value, callback) {
  if (value.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,18}$/)) {
    callback()
  } else {
    callback(new Error('请输入6-18位字母和数字的组合'))
  }
}

export function phoneNumberValidator (rule, value, callback) {
  if (isPhone.test(value)) {
    callback();
  } else {
    callback(new Error('手机号码不正确'));
  }
}

const isLandline = /^0[1-9][0-9]{1,2}-[2-8][0-9]{6,7}$/
export function LandlineValidator (rule, value, callback) {
  if (isLandline.test(value)) {
    callback()
  } else {
    callback(new Error('座机号码不正确'))
  }
}

export function phoneOrLandlineValidator (rule, value, callback) {
  if (isPhone.test(value) || isLandline.test(value)) {
    callback()
  } else {
    callback(new Error('联系电话不正确'))
  }
}
