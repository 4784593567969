import { render, staticRenderFns } from "./protocol.vue?vue&type=template&id=aabb27da&scoped=true"
import script from "./protocol.vue?vue&type=script&lang=js"
export * from "./protocol.vue?vue&type=script&lang=js"
import style0 from "./protocol.vue?vue&type=style&index=0&id=aabb27da&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aabb27da",
  null
  
)

export default component.exports