<template>
	<div class="Login">
		<Protocol v-if="showProtocol" @isShowProtocol="handleIsShowProtocol"></Protocol>
		<div v-else>
			<div class="toLogin" @click="toRouter('/login')">账号登录</div>
			<div class="LoginMethod">
				<div v-if="regMethods.includes('handset')" @click="SwitchingMethod('handset')" class="Method fl" :class="{ highlight: wayToRegister }">
					手机号注册<span v-show="wayToRegister"></span>
				</div>

				<div v-if="regMethods.length == 2" class="fl cutApart"></div>

				<div v-if="regMethods.includes('mailbox')" @click="SwitchingMethod('mailbox')" class="Method fl" :class="{ highlight: !wayToRegister }">
					邮箱注册<span v-show="!wayToRegister"></span>
				</div>

				<div class="clear"></div>
			</div>
			<div class="EditingArea">
				<div v-show="wayToRegister" class="vanField">
					<ul>
						<li>
							<van-field v-model="phone" center clearable :border="false" use-button-slot placeholder="请输入手机号" />
						</li>
						<li class="authcodeInputWrapper">
							<van-field v-model="Verification" class="VerificationStyle fl" clearable placeholder="请输入验证码" :border="false" />

							<div
								class="getVerificationStyle fr"
								@click="postAccountGetAuthCode('phone')"
								:style="{ color: Countdown1 > 0 ? '#BBBBBB' : '#333333' }"
							>
								{{ VerificationStatus }}
							</div>

							<div class="clear"></div>
						</li>
						<li class="passwordInputWrapper">
							<van-field
								class="passwordStyle fl"
								v-model="password"
								clearable
								:type="passwordType1"
								placeholder="设置8至20位登录密码"
								:border="false"
							/>

							<div class="VisibleCutApart fl"></div>

							<div class="Visible fr" @click="setVisible('password')">
								<img class="fl" :src="isVisibleImg" alt="" />
							</div>
						</li>
						<li class="passwordInputWrapper">
							<van-field
								class="passwordStyle fl"
								v-model="password2"
								clearable
								:type="passwordType2"
								@blur="inputBlur"
								placeholder="请再次输入登录密码"
								:border="false"
							/>

							<div class="VisibleCutApart fl"></div>

							<div class="Visible fr" @click="setVisible('rePassword')">
								<img class="fl" :src="isVisibleImg2" alt="" />
							</div>
						</li>
					</ul>
				</div>
				<div v-show="!wayToRegister" class="vanField">
					<ul>
						<li>
							<van-field v-model="phone" center clearable :border="false" use-button-slot placeholder="请输入邮箱" />
						</li>
						<li class="authcodeInputWrapper">
							<van-field v-model="Verification" class="VerificationStyle fl" clearable placeholder="请输入验证码" :border="false" />

							<div
								class="getVerificationStyle fr"
								@click="postAccountGetAuthCode('mail')"
								:style="{ color: Countdown2 > 0 ? '#BBBBBB' : '#333333' }"
							>
								{{ VerificationStatus }}
							</div>

							<div class="clear"></div>
						</li>
						<li class="passwordInputWrapper">
							<van-field
								class="passwordStyle fl"
								v-model="password"
								clearable
								:type="passwordType1"
								placeholder="设置8至20位登录密码"
								:border="false"
							/>

							<div class="VisibleCutApart fl"></div>

							<div class="Visible fr" @click="setVisible('password')">
								<img class="fl" :src="isVisibleImg" alt="" />
							</div>
						</li>
						<li class="passwordInputWrapper">
							<van-field
								class="passwordStyle fl"
								v-model="password2"
								clearable
								:type="passwordType2"
								@blur="inputBlur"
								placeholder="请再次输入登录密码"
								:border="false"
							/>

							<div class="VisibleCutApart fl"></div>

							<div class="Visible fr" @click="setVisible('rePassword')">
								<img class="fl" :src="isVisibleImg2" alt="" />
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="OperatingSpace">
				<div class="protocolStyle">
					<van-checkbox class="fl" v-model="protocol" label-disabled></van-checkbox>
					<span class="fl" @click="ClearanceAgreement">我同意</span>
					<a class="fl" @click="goPage('service-agreement')">《用户协议》</a>
					<span class="fl">及</span>
					<a class="fl" @click="goPage('privacy')">《法律声明及隐私政策》</a>
					<div class="clear"></div>
				</div>
				<van-button round type="info" @click="toRegist" :class="phone && protocol ? 'van-button--info' : 'noneVanButton'">注册</van-button>
			</div>
		</div>
	</div>
</template>
<script>
import md5 from 'js-md5'
import { mapState } from 'vuex'
import config from '../../../config/url'
import protocol from './protocol'
import yincang from '../asset/images/icon-yincang.png'
import xianshi from '../asset/images/icon-xianshi.png'
import { isPhone, isEmail, passReg } from '../../../utils/validate'
export default {
	name: 'registered',
	data() {
		return {
			showProtocol: false,
			protocol: true,
			phone: null,
			password: null,
			password2: null,
			Verification: null,
			Countdown1: 0,
			Countdown2: 0,
			passwordType1: 'password',
			passwordType2: 'password',
			clientHeight: 0,
			place: 'handset',
			//regMethods: ['handset', 'mailbox'],
			regMethods: ['handset'],
		}
    },
	components: {
		Protocol: protocol,
	},
	computed: {
		wayToRegister() {
			return this.place === 'handset'
		},
		VerificationStatus() {
			if (this.place === 'handset') {
				if (this.Countdown1) {
					return `重新获取 ${this.Countdown1}s`
				} else {
					return '发送验证码'
				}
			} else {
				if (this.Countdown2) {
					return `重新获取 ${this.Countdown2}s`
				} else {
					return '发送验证码'
				}
			}
		},
		isVisibleImg() {
			return this.passwordType1 === 'password' ? yincang : xianshi
		},
		isVisibleImg2() {
			return this.passwordType2 === 'password' ? yincang : xianshi
		},
		...mapState(['branchId', 'branchUcode']), // 人社子机构id
	},
	created() {
		this.initConfig()
	},
	mounted() {
		this.clientHeight = document.documentElement.clientHeight
	},
	methods: {
		//   用户协议跳转页面
		goPage(url) {
			this.$router.push(url)
		},
		initConfig() {
			/* 
            手机号+验证码 is_allow_telephone_register 
            手机号+密码   is_allow_email_register 
        */
			let frontendConfig = this.$store.state.dynamic.frontendConfig || []
			frontendConfig.length &&
				frontendConfig.forEach((item) => {
					switch (item.key) {
						case 'is_allow_telephone_register':
							if (item.value === 'false') {
								let index = this.regMethods.findIndex((item) => {
									return item === 'handset'
								})
								this.regMethods.splice(index, 1)
							}
							break
						case 'is_allow_email_register':
							if (item.value === 'false') {
								let index = this.regMethods.findIndex((item) => {
									return item === 'mailbox'
								})
								this.regMethods.splice(index, 1)
							}
							break
						default:
							break
					}
				})
			this.place = this.regMethods[0]
		},
		handleIsShowProtocol(data) {
			this.showProtocol = data
		},
		setVisible(type) {
			if (type === 'rePassword') {
				if (this.passwordType2 === 'password') {
					this.passwordType2 = 'text'
					return false
				}
				this.passwordType2 = 'password'
			} else {
				if (this.passwordType1 === 'password') {
					this.passwordType1 = 'text'
					return false
				}
				this.passwordType1 = 'password'
			}
        },
        async getConfig(){
            return this.$api.configuration.getConfigurationNew().then(res => {
                if(res.success){
                    this.branchId = res.data.branchId
                    this.branchUcode = res.data.branchUcode
                }
            })
        },
		async toRegist() {
			if (!this.protocol) return
			if (!passReg.test(this.password)) {
				this.$notify({ type: 'warning', message: '请设置8-20位，数字、大小写字母、字符组合密码' })
				return
			}
            if (!this.inputBlur()) return
            
            if(!this.branchId){ //注册到指定机构
                await this.getConfig()
            }

			const data = {
				account: this.phone,
				authCode: this.Verification,
				password: this.password,
				branchUcode: this.branchUcode, //注册到当前机构
			}
			await this.$api.rensheUsercenter.postAccountRegister(data).then((res) => {
				if (res.success) {
					this.$toast({
						message: '恭喜你，注册成功',
					})
					localStorage.setItem('token', res.data)
					this.toLogin()
				}
			})
		},
		getUserInfo() {
			this.$api.usercenter.getAccountUserInfo({}).then((res) => {
				localStorage.setItem('WDuserInfo', JSON.stringify(res.data))
				this.$store.commit('setUserInfo', res.data)
			})
		},
		// 查询用户id
		findUserId() {
			this.$api.usercenter.findUser().then((res) => {
				if (res.code == 0) {
					let subUser = { id: res.data.id }
					localStorage.setItem('subUserInfo', JSON.stringify(subUser))
				}
			})
		},
		async toLogin() {
			try {
				await this.getFindUserBranchList()
			} catch (err) {
				localStorage.removeItem('token')
				if (err) return
			}

			// 获取用户信息
			this.getUserInfo()
			// 获取用户id
			this.findUserId()

			if (sessionStorage.getItem('realName')) {
				location.replace('/idcard')
			} else {
				if (this.$route.query.name) {
					this.$router.push({
						path: this.$route.query.name + window.location.search,
					})
				} else {
					this.$router.push('/distributionHomePage')
				}
			}
		},
		getFindUserBranchList() {
			return this.$api.account.getFindUserBranchList({}).then((res) => {
				if (res.data && res.data.length) {
					const branchList = res.data

					if (branchList.length) {
						localStorage.setItem('branchList', JSON.stringify(branchList))

						// 登录域名指定的子机构
						let currentBranch = branchList.find((item) => {
							let branch = md5(this.branchId + item.token).toUpperCase()
							// console.log(this.branchId, item.token, '---', branch)
							return item.branch === branch
						})

						if (currentBranch) {
							localStorage.setItem('code', currentBranch.code)
							localStorage.setItem('organ', currentBranch.organ)
							localStorage.setItem('actor', currentBranch.actor)
							localStorage.setItem('branch', currentBranch.branch)
							currentBranch.channel && localStorage.setItem('channel', currentBranch.channel)
							this.$sensors.login(currentBranch.code)
						} else {
							this.$notify({
								type: 'danger',
								message: '暂未加入当前机构',
							})
							return Promise.reject('暂未加入当前机构')
						}
					}
				} else {
					return Promise.reject(res.data.message)
				}
			})
		},
		inputBlur() {
			let result = true
			if (!this.password || !this.password2) {
				result = false
			}
			if (this.password && this.password2 && this.password !== this.password2) {
				this.$notify({ type: 'warning', message: '两次密码输入不一致，请重新输入' })
				result = false
			}
			return result
		},
		toRouter(path) {
			this.$router.push({ path })
		},
		ClearanceAgreement() {
			this.protocol = !this.protocol
		},
		SwitchingMethod(val) {
			this.place = val
			// this.protocol= false;
			this.phone = null
			this.password = null
			this.password2 = null
			this.Verification = null
			this.passwordType1 = 'password'
			this.passwordType2 = 'password'
		},
		setCountdown1() {
			let Countdown = null
			Countdown = setInterval(() => {
				this.Countdown1--
				if (this.Countdown1 <= 0) {
					clearInterval(Countdown)
				}
			}, 1000)
		},
		setCountdown2() {
			let Countdown = null
			Countdown = setInterval(() => {
				this.Countdown2--
				if (this.Countdown2 <= 0) {
					clearInterval(Countdown)
				}
			}, 1000)
		},
		postAccountGetAuthCode(type) {
			let CountdownType = type === 'phone' ? 'Countdown1' : 'Countdown2'
			if (this[CountdownType] > 0) return
			if (isPhone.test(this.phone) || isEmail.test(this.phone)) {
				if (type === 'phone') {
					this.Countdown1 = 60
					this.setCountdown1()
					this.getAuthCode()
				} else {
					this.Countdown2 = 60
					this.setCountdown2()
					this.getAuthCode()
				}
			} else {
				let account = this.place === 'handset' ? '手机号' : '邮箱账号'
				this.$notify({ type: 'warning', message: account + '格式有误,请检查一下吧' })
			}
		},
		getAuthCode() {
			let data = { account: this.phone, type: 2 }
			this.$sensors.track('UM_Click_Verification_Code_Button')
			this.$api.usercenter.getAuthCodeCommon({ data })
		},
	},
}
</script>
<style lang="stylus" scoped>
.Login{
  background-color #FFFFFF
  min-height 100vh
}
.toLogin{
  padding-right 24px
  height 100px
  line-height 100px
  text-align right
  font-size 30px
  color #333
}
.LoginMethod {
  padding-left 32px
  padding-top 22px
  .Method {
    padding 0 32px
    font-size 44px
    font-weight 900
    color #BBBBBB
    position relative
    span {
      width 48px
      height 8px
      display inline-block
      background-color #3660FF
      border-radius 4px
      position absolute
      margin-left -24px
      bottom -24px
      left 50%
    }
    &.highlight {
      color #333333
    }
  }
  .cutApart {
    width  2px
    height 44px
    margin-top 10px
    border-radius 1px
    background-color #D7D7D7
  }
}
.EditingArea{
  width 622px
  padding-top 55px
  position relative
  margin-left -311px
  left 50%
  li {
    padding 1px 0
    height 122px
    overflow hidden
    border-bottom 1px solid #D7D7D7
    .passwordStyle{
      width 548px
      display inline-block
    }
    .VisibleCutApart{
      margin 0 24px
      width  2px
      height 32px
      border-radius 1px
      background-color #D7D7D7
    }
    .Visible{
      display inline-block
      img{
        width 48px
      }
    }
  }
  li.passwordInputWrapper{
    display flex
    align-items center
  }
  li.authcodeInputWrapper{
    display flex
    align-items center
    justify-content space-between
  }
  .van-cell{
    padding 0
    height 58px
    line-height 58px
    font-size 18px
    background transparent
  }
}
.OperatingSpace{
  width 622px
  position relative
  margin-left -311px
  left 50%
  .van-button{
    width 100%
    height 44px
    line-height 44px
    margin-top 32px
    margin-bottom 24px
    span{
      color #FFFFFF
      font-size 16px
    }
  }
  .noneVanButton{
    background-color #C0D5FF
    border 1px solid #C0D5FF
  }
  .van-button--info{
    background-color linear-gradient(136deg, #227FFF 0%, #316FFF 100%)
    border 1px solid linear-gradient(136deg, #227FFF 0%, #316FFF 100%)
  }
  .protocolStyle{
    margin-top 48px
    .van-checkbox{
      margin-right 16px
    }
    span, a{
      display inline-block
      font-size 26px
      line-height 30px
      margin-top 6px
    }
    span {
      color #666666
    }
    a {
      color #3760FF
    }
  }
}
.VerificationStyle{
  width 420px
}
.getVerificationStyle{
  color #333333
  font-size 30px
}
</style>
